import React from "react"
import PropTypes from "prop-types"

function Title({
  content,
  colorTitle,
  colorBackgroundTitle,
  classList,
  tag,
  rotated,
  titleAlign,
  color,
  fontSize,
}) {
  let textAlign
  let sizeFont

  switch (fontSize) {
    case "32px":
      sizeFont = "32px"
      break
    case "42px":
      sizeFont = "42px"
      break
    case "64px":
      sizeFont = "64px"
      break
    default:
      sizeFont = null
  }

  switch (titleAlign) {
    case "Center":
      textAlign = "text-center"
      break
    case "Center Mobile":
      textAlign = "text-center lg:text-left"
      break
    case "Center Desktop":
      textAlign = "text-left lg:text-center"
      break
    case "Left":
      textAlign = "text-left"
      break
    case "Right":
      textAlign = "text-right lg:text-left"
      break
    default:
      textAlign = "text-center"
  }

  const search = " "
  const replaceWith = " \u00a0"
  let nbspContent = ""
  if (content && content !== "") {
    nbspContent = content.split(search).join(replaceWith)
  } else {
    nbspContent = content
  }

  return (
    <div
      className={`block w-full mb-4 whitespace-pre-wrap ${textAlign}`}
      data-layer-component="title"
    >
      {(tag === "h1" && (
        <h1
          className={`inline-block ${
            rotated ? "transform -rotate-2" : ""
          } ${classList} ${colorTitle || "text-black"}`}
        >
          <span
            className={`leading-tight ${
              colorBackgroundTitle ? "px-4 sm:px-6 pb-1 pt-3" : "p-0"
            } ${colorBackgroundTitle}`}
            style={{ color: color || null, fontSize: sizeFont }}
          >
            {nbspContent}
          </span>
        </h1>
      )) ||
        (tag === "h2" && (
          <h2
            className={`inline-block ${
              rotated ? "transform -rotate-2" : ""
            } ${classList} ${colorTitle || "text-black"}`}
          >
            <span
              className={`leading-tight ${
                colorBackgroundTitle ? "px-4 sm:px-6 pb-1 pt-3" : "p-0"
              } ${colorBackgroundTitle}`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </span>
          </h2>
        )) ||
        (tag === "h3" && (
          <h3
            className={`inline-block ${
              rotated ? "transform -rotate-2" : ""
            } ${classList} ${colorTitle || "text-black"}`}
          >
            <span
              className={`leading-tight ${
                colorBackgroundTitle ? "px-4 sm:px-6 pb-1 pt-3" : "p-0"
              } ${colorBackgroundTitle}`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </span>
          </h3>
        )) ||
        (tag === "h4" && (
          <h4
            className={`inline-block font-noto text-xl ${
              rotated ? "transform -rotate-2" : ""
            } ${classList} ${colorTitle || "text-black"}`}
          >
            <span
              className={`leading-tight ${
                colorBackgroundTitle ? "px-4 sm:px-6 pb-1 pt-3" : "p-0"
              } ${colorBackgroundTitle}`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </span>
          </h4>
        )) ||
        (tag === "h5" && (
          <h5
            className={`inline-block ${
              rotated ? "transform -rotate-2" : ""
            } ${classList} ${colorTitle || "text-black"}`}
          >
            <span
              className={`leading-tight ${
                colorBackgroundTitle ? "px-4 sm:px-6 pb-1 pt-3" : "p-0"
              } ${colorBackgroundTitle}`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </span>
          </h5>
        )) ||
        (tag === "h6" && (
          <h6
            className={`inline-block ${
              rotated ? "transform -rotate-2" : ""
            } ${classList} ${colorTitle || "text-black"}`}
          >
            <span
              className={`leading-tight ${
                colorBackgroundTitle ? "px-4 sm:px-6 pb-1 pt-3" : "p-0"
              } ${colorBackgroundTitle}`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </span>
          </h6>
        )) ||
        (tag === "p" && (
          <p
            className={`inline-block ${
              rotated ? "transform -rotate-2" : ""
            } ${classList} ${colorTitle || "text-black"}`}
          >
            <span
              className={`leading-tight ${
                colorBackgroundTitle ? "px-4 sm:px-6 pb-1 pt-3" : "p-0"
              } ${colorBackgroundTitle}`}
              style={{ color: color || null, fontSize: sizeFont }}
            >
              {content}
            </span>
          </p>
        )) ||
        null}
    </div>
  )
}

Title.propTypes = {
  content: PropTypes.string,
  colorTitle: PropTypes.string,
  colorBackgroundTitle: PropTypes.string,
  classList: PropTypes.string,
  tag: PropTypes.string,
  rotated: PropTypes.bool,
}

Title.defaultProps = {
  content: "",
  colorTitle: "",
  colorBackgroundTitle: "",
  classList: "",
  tag: "",
  rotated: false,
}

export default Title
